import React, { Fragment, useContext } from 'react';
import { graphql } from 'gatsby';
import GraphQLErrorList from '../../components/graphql-error-list';
import { Layout, LanguageContext } from '../../layout/Layout';
import { FlexList } from '../../components/FlexList';
import SEO from '../../components/seo';
import HeaderPadding from '../../layout/HeaderPadding';
import { usePageTitle } from '../../hooks/usePageTitle';

export const query = graphql`
  query JobsPageQueryNb {
    allSanityJob(filter: { slug: { current: { ne: null } } }) {
      edges {
        node {
          _id
          title {
            nb
            en
          }
          slug {
            current
          }
        }
      }
    }
  }
`;

const JobsPage = (props) => {
  const { data, errors } = props;
  const lang = useContext(LanguageContext);
  usePageTitle('Søk jobb');

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const listData = data.allSanityJob.edges.map(({ node: { _id, title, slug: { current: slug } } }) => ({
    id: _id,
    title: title.nb,
    url: `/${lang}/job/${slug}`,
  }));

  return (
    <Fragment>
      <SEO title="Søk jobb" />
      <HeaderPadding />
      <FlexList data={listData} />
      <HeaderPadding />
    </Fragment>
  );
};

export default JobsPage;
